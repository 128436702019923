<script>export let show;
export let transition = () => ({});
</script>

{#if show}
  <div transition:transition {...$$restProps}>
    <slot />
  </div>
{:else}
  <!-- Use invisible as well to disable tabbing of children -->
  <div class="hidden invisible">
    <slot />
  </div>
{/if}
