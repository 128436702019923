<script>import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Item from "./top-nav-menu-item.svelte";
import FaIcon from "../fa-icon.svelte";
import Popper from "../popper.svelte";
export let entry;
export let i;
let target;
let show = false;
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- Mirrors behavior from https://www.w3.org/WAI/tutorials/menus/flyout/#keyboard-users -->
<li
  class="group grow"
  on:mouseover={() => (show = true)}
  on:mouseleave={() => (show = false)}
  on:focusout={() => (show = target.matches(":focus-within"))}
  bind:this={target}
>
  <button
    class="flex-center w-full gap-2 group-hover:text-primary"
    class:text-red-6={i === 0}
    aria-expanded={show ? "true" : "false"}
    on:click={() => (show = !show)}
  >
    <div class="text-center space-x-1">
      {#if typeof entry.icon === "string"}
        <i class={entry.icon} />
      {:else if typeof entry.icon === "object"}
        <FaIcon icon={entry.icon}></FaIcon>
      {/if}
      <span>{entry.name}</span>
    </div>
    <FaIcon icon={faChevronDown} />
  </button>
  {#if target}
    <Popper {target} {show}>
      <ul class="m-6 px-4 py-2 space-y-2 bg-white rounded drop-shadow">
        {#each entry.menu as link}
          <Item {link} />
        {/each}
      </ul>
    </Popper>
  {/if}
</li>
