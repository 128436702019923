<script>import Popper from "../popper.svelte";
export let link;
let target;
let show = false;
const options = {
    strategy: "absolute",
    placement: "right-start",
    modifiers: [],
};
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<li bind:this={target} on:mouseover={() => (show = true)} on:mouseleave={() => (show = false)}>
  {#if link.href}
    <a
      class="block pxxy-1 hover:(text-white bg-primary) rounded"
      href={link.href}
      rel={link.nofollow ? "nofollow" : undefined}
      data-sveltekit-reload={link.reload ?? true}
    >
      {link.name}
    </a>
    {#if "menu" in link && target}
      <Popper {target} {show} {options}>
        <ul class="w-max mx-6 pxxy-2 space-y-2 bg-white rounded drop-shadow">
          {#each link.menu as sub}
            {#if "href" in sub}
              <li>
                <a
                  class="block pxxy-1 hover:(text-white bg-primary) rounded"
                  href={sub.href}
                  rel={sub.nofollow ? "nofollow" : undefined}
                  data-sveltekit-reload={sub.reload ?? true}
                >
                  {sub.name}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </Popper>
    {/if}
  {/if}
</li>

<style>
  a:hover[href="/polaris-wraps"] {
    --at-apply: text-white bg-red-6;
  }
  a:hover[href="/ski-doo-wraps"],
  a:hover[href="/can-am-wraps"] {
    --at-apply: text-black bg-yellow;
  }
  a:hover[href="/arctic-cat-wraps"] {
    --at-apply: text-white bg-green-6;
  }
  a:hover[href="/lynx-wraps"] {
    --at-apply: text-black bg-red-6;
  }
  a:hover[href="/yamaha-wraps"] {
    --at-apply: text-white bg-blue-9;
  }
  a:hover[href="/honda-wraps"] {
    --at-apply: bg-red-6;
  }
  a:hover[href="/kawasaki-wraps"] {
    --at-apply: bg-lime-6;
  }
</style>
