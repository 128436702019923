<script>import "overlayscrollbars/overlayscrollbars.css";
import Side from "./side.svelte";
import Top from "./top.svelte";
export let navigation;
export let tenantId;
export let imageUrlBase;
let loading = true;
let open = false;
</script>

<header class="sticky top-0 z-50">
  <Top {navigation} {tenantId} {loading} {imageUrlBase} bind:open />
  <Side {navigation} {loading} {imageUrlBase} bind:open />
</header>
