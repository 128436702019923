<script>import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { fade } from "svelte/transition";
import Colorway from "./colorway.svelte";
import IconAccount from "./icon-account.svelte";
import IconCart from "./icon-cart.svelte";
import SideLink from "./side-link.svelte";
import SideMenu from "./side-menu.svelte";
import { clickOutside } from "../actions/click-outside";
import { focusTrap } from "../actions/focus-trap";
import FaIcon from "../fa-icon.svelte";
import { breakpoint } from "../media";
import SeoHide from "../seo-hide.svelte";
export let navigation;
export let open;
export let loading;
export let imageUrlBase;
$: if ($breakpoint.lg)
    open = false;
$: entries = navigation.filter(({ depends }) => !depends);
</script>

<svelte:body on:keydown={({ code }) => code === "Escape" && (open = false)} />
{#if open}
  <div class="fixed inset-0 z-10 font-medium" data-testid="sidebar-bg" transition:fade>
    <div class="h-full w-full bg-black opacity-50 cursor-pointer" />
  </div>
{/if}
<SeoHide show={open}>
  <div
    class="fixed w-78 h-full top-0 right-0 p-4 bg-white z-20"
    use:clickOutside={{ enabled: open, callback: () => (open = false) }}
    use:focusTrap
  >
    <nav class="m-4 text-neutral-8" aria-label="Main">
      <button
        class="ml-auto h-8 w-8 p-4 flex-center bg-primary text-white"
        title="Close Navigation Menu"
        on:keydown={({ code }) => code === "Enter" && (open = false)}
        on:click={() => (open = false)}
      >
        <FaIcon icon={faXmark} />
      </button>
      <ul class="divide-y-1">
        <!-- Use breakpoint store rather than util classes due to parent's divide styling -->
        {#if !$breakpoint.md}
          <li><Colorway class="py-4" /></li>
        {/if}
        {#if !$breakpoint.xs}
          <IconCart
            class="flex items-center gap-2 py-4 hover:text-primary"
            {imageUrlBase}
            {loading}
          />
        {/if}
        {#if !$breakpoint.sm}
          <IconAccount class="flex items-center gap-2 py-4 hover:text-primary" />
        {/if}
        {#each entries as entry}
          {#if "menu" in entry}
            <SideMenu {entry} on:click={() => (open = false)} />
          {:else}
            <SideLink {entry} on:click={() => (open = false)} />
          {/if}
        {/each}
      </ul>
    </nav>
  </div>
</SeoHide>
