<script>import FaIcon from "../fa-icon.svelte";
export let entry;
export let i;
</script>

<li class="grow flex-center gap-2 hover:text-primary" class:text-red-6={i === 0}>
  <a
    class="flex-(center wrap) gap-x-2 text-center"
    href={entry.href}
    rel={entry.nofollow ? "nofollow" : undefined}
    data-sveltekit-reload={entry.reload ?? true}
  >
    {#if typeof entry.icon === "string"}
      <i class={entry.icon} />
    {:else if typeof entry.icon === "object"}
      <FaIcon icon={entry.icon} />
    {/if}
    <span>{entry.name}</span>
  </a>
</li>
