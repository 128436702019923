<script>export let image;
export let alt;
</script>

<img
  {...$$restProps}
  src={typeof image === "object" ? image.url : image}
  width={typeof image === "object" ? image.width : undefined}
  height={typeof image === "object" ? image.height : undefined}
  {alt}
/>
