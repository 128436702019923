<script context="module">import { faCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { fromJson } from "@fxwrapr/util";
import { stripTags } from "@fxwrapr/util/string";
import resolve from "@jridgewell/resolve-uri";
import { z } from "zod";
import { getSessionContext } from "../context";
import FaIcon from "../fa-icon.svelte";
import { GIFTCARD_REGEX, parsePrice } from "../woocommerce";
</script>

<script>export let item;
export let imageUrlBase;
const { cart } = getSessionContext();
const raw = item.item_data.find(({ key }) => key === "_fxw_data");
const wrap = fromJson(raw?.value, z.object({
    _schema: z.literal("0.0.1"),
    model_str: z.string(),
    design_str: z.string(),
    colorway_id: z.string(),
    colorway_index: z.number().int(),
}));
function createLink(item) {
    const slug = item.extensions.fxwrapr.product_slug;
    if (!slug)
        return item.permalink;
    const route = GIFTCARD_REGEX.test(slug) ? "product" : "item";
    return `/${route}/${slug}`;
}
</script>

<div class="relative flex items-start gap-2">
  {#if wrap}
    <img
      class="w-16 shrink-0 object-contain"
      src={resolve(`colorway/${wrap.colorway_id}`, imageUrlBase)}
      alt=""
    />
  {:else if item.images[0]?.src}
    <img class="w-16 shrink-0 object-contain" src={item.images[0].src} alt="" />
  {:else}
    <div class="w-16 h-16 shrink-0 bg-neutral-2" />
  {/if}
  <div>
    {#if wrap}
      <a class="hover:text-primary" href={item.permalink}>
        {wrap.model_str} - {wrap.design_str} (#{wrap.colorway_index})
      </a>
      <div class="text-neutral-4">
        {#each item.item_data.filter(({ hidden }) => !hidden) as data}
          <p>{data.key}: {data.value}</p>
        {/each}
        <p>{item.quantity} x {parsePrice(item.totals, "line_subtotal")}</p>
      </div>
    {:else}
      <a
        class="hover:text-primary"
        href={item.permalink.startsWith("/pages/") ? item.permalink : createLink(item)}
      >
        {stripTags(item.name)}
      </a>
      <p class="text-neutral-4">{item.quantity} x {parsePrice(item.prices, "price")}</p>
    {/if}
  </div>
  <button
    class="group absolute left-0.25 top-1 leading-none text-xs"
    on:click={() => cart.deleteItem(item.key)}
  >
    <div class="fa-stack">
      <FaIcon
        class="text-neutral-5 group-hover:text-neutral-8 opacity-50"
        icon={faCircle}
        stack={2}
      />
      <FaIcon class="text-white" icon={faXmark} stack={1} />
    </div>
  </button>
</div>

<style>
  .fa-stack {
    position: relative;
    display: inline-block;
    height: 2em;
    width: 2.5em;
    vertical-align: middle;
  }
</style>
