<script context="module">import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { writable } from "svelte/store";
import { slide } from "svelte/transition";
import FaIcon from "../fa-icon.svelte";
import SeoHide from "../seo-hide.svelte";
const open = writable(false);
</script>

<script>export let entry;
const key = Symbol();
$: show = key === $open;
</script>

<li>
  <button
    type="button"
    class="w-full text-left"
    aria-expanded={show ? "true" : "false"}
    on:click={() => ($open = show ? false : key)}
  >
    <div class="w-full flex py-4 items-center justify-between hover:text-primary cursor-pointer">
      <div class="space-x-1">
        {#if typeof entry.icon === "string"}
          <i class={entry.icon} />
        {:else if typeof entry.icon === "object"}
          <FaIcon icon={entry.icon} fw></FaIcon>
        {/if}
        <span>{entry.name}</span>
      </div>
      <FaIcon
        class="text-gray-3 motion-safe:transition-transform"
        icon={faPlus}
        rotate={show ? "45deg" : undefined}
      />
    </div>
  </button>
  <SeoHide transition={slide} {show}>
    <ul class="font-normal pl-4 pb-4 text-sm space-y-1">
      {#each entry.menu as link}
        {#if "href" in link}
          <li>
            <a
              class="block pxxy-1 hover:(text-white bg-primary) rounded"
              href={link.href}
              rel={link.nofollow ? "nofollow" : undefined}
              data-sveltekit-reload={link.reload ?? true}
              on:click
            >
              {link.name}
            </a>
          </li>
        {/if}
      {/each}
    </ul>
  </SeoHide>
</li>

<style>
  a:hover[href="/catalog/polaris"] {
    --at-apply: font-medium text-white bg-red-6;
  }
  a:hover[href="/catalog/ski-doo"] {
    --at-apply: font-medium text-black bg-yellow;
  }
  a:hover[href="/catalog/arctic-cat"] {
    --at-apply: font-medium text-white bg-green-6;
  }
  a:hover[href="/catalog/lynx"] {
    --at-apply: font-medium text-black bg-red-6;
  }
  a:hover[href="/catalog/yamaha"] {
    --at-apply: font-medium text-white bg-blue-9;
  }
</style>
