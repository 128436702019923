<script>import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import IconCartPopper from "./icon-cart-popper.svelte";
import { getSessionContext } from "../context";
import FaIcon from "../fa-icon.svelte";
import { parsePrice } from "../woocommerce";
export let imageUrlBase;
const { cart } = getSessionContext();
let target;
let show = false;
const override = cart.show;
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<!-- svelte-ignore a11y_mouse_events_have_key_events -->
<li on:mouseover={() => (show = true)} on:mouseleave={() => (show = false)}>
  <a
    class="{$$props.class} whitespace-nowrap"
    href="/cart/"
    title="Cart"
    data-sveltekit-reload
    bind:this={target}
    on:focus={() => (show = true)}
    on:blur={() => (show = false)}
  >
    <FaIcon icon={faCartShopping} />
    {#if $cart.state === "error"}
      Error!
    {:else if $cart.data}
      {parsePrice($cart.data.totals, "total_items")}
      <div class="px-1 inline-flex-center bg-primary text-xs text-white rounded-full">
        {$cart.data.items_count}
      </div>
    {:else if $cart.state === "loading" && $cart.optimistic}
      ${$cart.optimistic.total.toFixed(2)} USD
      <div class="px-1 inline-flex-center bg-primary text-xs text-white rounded-full">
        {$cart.optimistic.count}
      </div>
    {:else}
      Loading...
    {/if}
  </a>
  <IconCartPopper {imageUrlBase} {target} show={show || $override} />
</li>
