export const FOCUSABLE = [
    "a[href]",
    "area[href]",
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    "select:not([disabled]):not([aria-hidden])",
    "textarea:not([disabled]):not([aria-hidden])",
    "button:not([disabled]):not([aria-hidden])",
    "iframe",
    "object",
    "embed",
    "[contenteditable]",
    '[tabindex]:not([tabindex^="-"])',
].join(",");
export const focusTrap = (node, input = true) => {
    let enabled = input;
    const handler = (event) => {
        if (!enabled || event.key !== "Tab")
            return;
        event.preventDefault();
        event.stopPropagation();
        const current = document.activeElement;
        const contained = node.contains(current);
        // Get visible, focusable elements
        const elements = [...node.querySelectorAll(FOCUSABLE)].filter((element) => {
            if (!(element instanceof HTMLElement))
                return false;
            const computed = document.defaultView?.getComputedStyle(element);
            return !!(computed &&
                computed.getPropertyValue("display") !== "none" &&
                computed.getPropertyValue("visibility") !== "hidden");
        });
        const fallback = elements.at(event.shiftKey ? -1 : 0);
        if (!(current instanceof HTMLElement) || !contained)
            fallback?.focus();
        else {
            const index = elements.indexOf(current);
            const target = elements[index + (event.shiftKey ? -1 : 1)] ?? fallback;
            target?.focus();
        }
    };
    node.addEventListener("keydown", handler);
    return {
        update: (input = true) => {
            enabled = input;
        },
        destroy: () => {
            node.removeEventListener("keydown", handler);
        },
    };
};
