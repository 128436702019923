<script>let value;
const submit = () => {
    const index = Number.parseInt(value);
    if (Number.isNaN(index))
        alert("The value you entered was not a valid colorway id!");
    else
        window.open(`/editor?colorway=${index}`, "_blank");
};
export {};
</script>

<form
  role="search"
  aria-label="MyDesign Search"
  class="{$$props.class} grow flex items-center justify-center flex-nowrap"
  method="GET"
  target="_blank"
  action="/editor"
  on:submit|preventDefault={submit}
>
  <input
    class="w-full px-3.75 py-1.25 b-(1 r-0) rounded-l-lg"
    type="text"
    placeholder="MyDesign ID"
    name="colorway"
    bind:value
  />
  <button class="bg-primary b-solid p-1.5 rounded-r-lg text-white" title="Load MyDesign ID">
    GO
  </button>
</form>
